@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  background-color: #f6f8fa;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bp3-input-group input {
  max-width: 390px;
}
.bp3-input-group.bp3-fill input {
  max-width: 100%;
}

textarea.bp3-input {
  min-height: 100px;
}

@media print {
  @page {
    size: 165mm 210mm;
  }
}